.dropdown-blur {
  backdrop-filter: none;
  background-color: #313131;
}

/* stylelint-disable-next-line */
@-moz-document url-prefix() {
  .dropdown-blur {
    backdrop-filter: blur(40px);
    background-color: rgba(255, 255, 255, 10%);
  }
}
