.wrapper-loading {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.to-raise {
  font-size: 34px;
  line-height: 38px;
  font-weight: 700;
  color: #ffffffde;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin: 16px 0;

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 30px;
  }
}

.data-loading {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  color: #94a3b8;
}

.bar {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  height: 22px;
  border-radius: 6px;
  background: #faf0f01a;
  margin: 16px 0;
}

.completion-bar {
  position: absolute;
  top: 48px;
  left: 0;
  height: 22px;
  border-radius: 6px;
  background: linear-gradient(90deg, #df832f 0%, #e3974f 33.33%, #e6a160 66.67%, #df832f 100%);
  margin: 16px 0;
}
