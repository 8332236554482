@keyframes float1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.token1 {
  animation: float1 8s ease-in-out infinite;
}

@keyframes float2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.token2 {
  animation: float2 8s 2s ease-in-out infinite;
}

.token1,
.token2 {
  width: 10vw; // Adjust this value as needed for your design
  height: 10vw; // Keeps the aspect ratio square
  object-fit: contain;
  max-width: 121px; // Ensures the image doesn't get too large on wide screens
  max-height: 121px;
}
