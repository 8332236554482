.input-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 36px;

  @media (max-width: 600px) {
    gap: 16px;
    flex-wrap: wrap;
  }

  .input-text {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #fff9;
    text-align: start;
    width: 100%;
    height: 56px;
    border: 1px solid #757575;
    border-radius: 6px;
    background: #faf0f01a;
    padding: 12px;
  }

  .commit-button {
    height: 56px;
    padding: 16px 40px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1.45px;
    background: linear-gradient(90deg, #df832f 0%, #e3974f 33.33%, #e6a160 66.67%, #df832f 100%);

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999;
    background: #ccc;
    color: #666;
    cursor: not-allowed;
  }
}
