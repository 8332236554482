.swap-wrapper {
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: 0 auto 32px;
}

.fromto {
  background-color: rgba(255, 255, 255, 5%);
  padding: 20px 16px;
  border: 1px solid rgba(255, 255, 255, 20%);
  border-radius: 20px;
}

.pre-selection-wrapper {
  display: flex;
  gap: 4px;
  padding: 0 4px;

  .asset {
    background-color: rgba(255, 255, 255, 5%);
    padding: 2px;
    border: 1px solid rgba(255, 255, 255, 20%);
    border-radius: 100%;
    cursor: pointer;
    width: 28px;
    height: 28px;
  }

  .asset:hover {
    border: 1px solid rgba(223, 131, 47, 100%);
  }

  @media (hover: none) {
    .asset:hover {
      border: 1px solid rgba(255, 255, 255, 20%);
    }
  }
}
