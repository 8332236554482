.box-ido {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  margin: 0;

  @media (max-width: 600px) {
    padding-top: 0;
  }

  .header-ido {
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 600px) {
      margin-top: 0;
    }
  }

  .logo {
    min-width: 70px;
    min-height: 70px;

    @media (max-width: 600px) {
      margin-top: 0;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-left: 24px;
  }

  .heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #ffffffde;
  }

  .tag {
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
    background: linear-gradient(90deg, #df832f 0%, #e3974f 33.33%, #e6a160 66.67%, #df832f 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .countdowns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 24px 0;

    @media (max-width: 600px) {
      margin: 8px 0;
    }
  }

  .data-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4px 0;
    gap: 16px;

    @media (max-width: 600px) {
      gap: 0;
    }
  }

  .data {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #cbd5e1;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #757575;
    border-radius: 6px;
    padding: 8px 16px;
    margin: 24px 0;
  }

  .claim-button {
    height: 56px;
    padding: 16px 40px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1.45px;
    background: linear-gradient(90deg, #df832f 0%, #e3974f 33.33%, #e6a160 66.67%, #df832f 100%);

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999;
    background: #ccc;
    color: #666;
    cursor: not-allowed;
  }
}
