.container-small {
  padding: 0 24px;
  @media (min-width: 768px) {
    max-width: 1000px;
    padding: 0 80px;
  }
  @media (min-width: 1040px) {
    max-width: 866px;
    padding: 0;
  }
}
