.mint-section {
  .title {
    line-height: 44px;
    font-size: 27px;
    font-weight: 500;
    color: #fff;
    @media (min-width: 1024px) {
      font-size: 42px;
      text-align: center;
      line-height: 48px;
    }
  }

  .subtitle {
    line-height: 25px;
    font-size: 16px;
    opacity: 0.88;
    color: #e6e6e6;
    margin-top: 10px;
    padding-bottom: 65px;
    @media (min-width: 1024px) {
      line-height: 30px;
      font-size: 20px;
      text-align: center;
    }
  }
}

.left-blob {
  top: 15%;
  left: -30%;
  opacity: 0.5;
  @media (min-width: 768px) {
    top: -40%;
    left: -30%;
    opacity: 1;
  }
}

.right-blob {
  right: -10%;
  top: 20%;
  opacity: 0.5;
  @media (min-width: 768px) {
    right: -30%;
    top: -80%;
    opacity: 1;
  }
}
