.info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 56px 16px 0;
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
    padding: 24px;
    gap: 16px;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #f8fafc;
    font-size: 42px;
    line-height: 48px;
    font-weight: 500;
    letter-spacing: 1px;

    @media (max-width: 600px) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  .text {
    color: #cfcfcf;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .learn-more {
    color: #df832f;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
