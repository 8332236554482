.dist {
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 56px;
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
    padding: 24px;
  }

  .title {
    color: #f8fafc;
    font-size: 26px;
    font-weight: 500;
    line-height: 48px;
  }

  .tokens {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .row {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #cfcfcf;
    padding-top: 8px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .action-button {
    height: 56px;
    padding: 16px 40px;
    margin-top: 24px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
    background: linear-gradient(90deg, #df832f 0%, #e3974f 33.33%, #e6a160 66.67%, #df832f 100%);

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .action-button:disabled {
    background: gray;
    cursor: not-allowed;
  }
}
