.details-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 100%;

  @media (min-width: 1280px) {
    margin-top: -112px;
  }

  .details-body {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    .details-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      margin-top: 20px;
      width: 300px;

      @media (min-width: 1024px) {
        padding: 16px 0;
        width: 30%;
      }

      @media (min-width: 1600px) {
        padding: 16px 30px;
      }

      .details-item-title {
        font-size: 12px;
        letter-spacing: 0.3px;
        color: #df832f;
        text-transform: uppercase;
        font-weight: 500;

        @media (min-width: 1024px) {
          font-size: 14px;
        }
      }

      .details-item-value {
        font-size: 40px;
        font-weight: 700;
        color: #fff;

        @media (min-width: 1280px) {
          font-size: 48px;
        }
      }
    }
  }
}
