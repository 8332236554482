.table-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  color: #dedbf2;
  padding: 16px;
  background-color: #262626;
  margin-bottom: 1px;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    align-items: center;
    padding: 20px 24px;
  }
}

.table-row-wrapper-promoted {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 1px;
  border-radius: 6px;
  color: #dedbf2;
  margin-bottom: 1px;
  background: linear-gradient(96.32deg, #6367ae -4.35%, #be5a4d 54.67%, #e2c585 107.72%);
  overflow: hidden;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    align-items: center;
  }
}

.ribbon {
  position: absolute;
  inset: 0 auto auto 0;
  background: #a081b4;
  transform-origin: 100% 0; /* or top left */
  transform: translate(-29.3%) rotate(-45deg);
  box-shadow: 0 0 0 999px #a081b4;
  clip-path: inset(0 -100%);
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.pool-bar {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  height: 20px;
  border-radius: 12px;
  background: #faf0f01a;
  margin: 8px 0;
}

.pool-completion-bar {
  position: absolute;
  top: 12px;
  left: 0;
  height: 20px;
  border-radius: 12px 0 0 12px;
  border-right: 3px solid white;
  background: #df832f;
  margin: 8px 0;
}

.percentage {
  position: absolute;
  top: 12px;
  margin: 9px 0;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.tag {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 6px;
  border: 1px solid gray;
  background: rgba(255, 255, 255, 5%);
  height: 22px;
  font-size: 10px;
  white-space: nowrap;
}

.sticky-table-header {
  display: none;
  justify-content: space-between;
  padding: 0.475rem 24px;
  z-index: 100;

  @media (min-width: 1024px) {
    display: flex;
    margin-bottom: -19px !important;
    top: -19px !important;
  }
  @media (min-width: 1280px) {
    margin-bottom: 0 !important;
    top: 0 !important;
  }
}

.tooltip-content {
  .tooltip-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    span:first-child {
      flex: 1;
    }

    span:last-child {
      flex: 0;
      margin-left: 10px; // Adjust as necessary to align the percentages
    }
  }
}
