.wrapper {
  max-width: 1104px;
  margin: 16px auto;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    margin: 48px 0 24px;
    cursor: pointer;

    p {
      color: #ffffffde;
      font-size: 34px;
      font-weight: 700;
      line-height: 36px;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 28px;
      }
    }
  }

  .arrow {
    width: 36px;
    height: 36px;
    color: #ffffffde;
  }

  .qa-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 8px;
    gap: 24px;
    border: 1px solid #757575;
    border-radius: 10px;
  }

  .qa {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 0 16px;
    border-bottom: 1px solid #757575;
  }

  .qa:last-child {
    border-bottom: none;
  }

  .question {
    color: #ffffffde;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .answer {
    color: #fff9;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
